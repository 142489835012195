var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.label)?_c('p',{staticClass:"text-capitalize label-text",class:{
        'h6--xsmall': _vm.bold,
        'text--default': !_vm.bold,
        'white--text': _vm.labelWhite,
        'dark--text': !_vm.labelWhite
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.required)?_c('p',{staticClass:"text-capitalize label-text ml-1 error--text",class:{
        'h6--xsmall': _vm.bold,
        'text--default': !_vm.bold
      }},[_vm._v(" * ")]):_vm._e(),(_vm.optional)?_c('p',{staticClass:"text-capitalize label-text ml-1 info--text text--lighten-2",class:{
        'h6--xsmall': _vm.bold,
        'text--default': !_vm.bold
      }},[_vm._v(" (Opsional) ")]):_vm._e()]),_c('v-textarea',_vm._g(_vm._b({attrs:{"placeholder":_vm.placeholder,"row-height":"7"},scopedSlots:_vm._u([(_vm.prepend)?{key:"prepend",fn:function(){return [_c('div',{staticClass:"textarea__prepend d-flex align-center justify-center gray rounded-lg"},[_c('p',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.prependName))])])]},proxy:true}:null],null,true)},'v-textarea',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }