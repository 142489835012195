<template>
  <div class="d-sm-flex align-center justify-space-between">
    <FormTextField
      v-model="search"
      class="searchbar text--large"
      outlined
      prepend-inner-icon="mdi-magnify"
      :placeholder="placeholder"
      dense
      hide-details
      clearable
      @input="onSearch"
    />

    <div class="d-flex align-center">
      <v-card width="120" flat>
        <v-select
          class="mt-3 mt-sm-0 mr-4 mr-sm-0"
          placeholder="Sort"
          :items="items.sort"
          item-text="label"
          item-value="key"
          hide-details
          dense
          outlined
          @input="onSetSort"
        />
      </v-card>

      <v-btn
        v-if="isCreated"
        height="42"
        depressed
        class="secondary lighten-5 h7--xxsmall text-capitalize mt-3 mt-sm-0 ml-sm-4"
        @click="onEmitAction()"
      >
        <v-icon small class="mr-2">mdi-plus</v-icon>
        Buat Baru
      </v-btn>
    </div>
  </div>
</template>

<script>
import { SORT } from '@/data/general'

export default {
  props: {
    body: Object,
    label: String,
    placeholder: String,
    actionCreate: String,
    defaultLabelFilter: {
      type: String,
      default: 'Semua'
    },
    isCreated: {
      type: Boolean,
      default: true
    },
    createDialog: {
      type: Boolean,
      default: false
    },

    sort: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      search: '',
      state: {
        sortKey: '',
        filterStatus: ''
      },
      items: {
        sort: SORT
      }
    }
  },

  computed: {
    isFilterStatus() {
      return this.filterStatus?.length > 0
    }
  },

  methods: {
    onSearch(val) {
      this.$emit('on:search', val || '')
    },

    onSetSort(val) {
      this.state.sortKey = val
      this.$emit('on:sort', val)
    },

    onSetFilterStatus(val) {
      this.state.filterStatus = val.key
      this.$emit('on:filterStatus', val.key)
    },

    onEmitAction() {
      if (this.createDialog) {
        this.$emit('on:dialog')
        return
      }
      this.$router.push(this.actionCreate)
    }
  }
}
</script>
