<template>
  <v-app>
    <GeneralMobileAppBar :drawer="drawer" @on:drawer="onSetDrawer" />
    <v-main class="o-app">
      <router-view />
      <GeneralSnackBar />
    </v-main>
    <GeneralBottomNavbar />
  </v-app>
</template>

<script>
import GeneralBottomNavbar from '@/components/GeneralBottomNavbar.vue'

export default {
  name: 'App',
  components: {
    GeneralBottomNavbar
  },
  data() {
    return {
      drawer: false
    }
  },

  methods: {
    onSetDrawer() {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
.o-app {
  padding: 16px !important;
  max-width: 600px;
  width: 100%;
  margin: 56px 0;
}

::v-deep .v-application--wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
