import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import AbsenceView from '@/views/AbsenceView.vue'
import ProfileView from '@/views/auth/ProfileView.vue'
import AdminDashboardView from '@/views/admin/AdminDashboardView.vue'
import AdminMemberView from '@/views/admin/member/AdminMemberView.vue'
import AdminMemberDetailView from '@/views/admin/member/AdminMemberDetailView.vue'
import AdminUserView from '@/views/admin/user/AdminUserView.vue'
import AdminUserDetailView from '@/views/admin/user/AdminUserDetailView.vue'
import ScanView from '@/views/admin/ScanView.vue'
import AdminProductView from '@/views/admin/product/AdminProductView.vue'
import AdminProductDetailView from '@/views/admin/product/AdminProductDetailView.vue'
import AdminTransactionView from '@/views/admin/transaction/AdminTransactionView.vue'
import AdminNewsView from '@/views/admin/news/AdminNewsView.vue'
import AdminNewsDetailView from '@/views/admin/news/AdminNewsDetailView.vue'
import ContentView from '@/views/ContentView.vue'
import ChangePassword from '@/views/auth/ChangePassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/scan',
    name: 'Home',
    component: ScanView,
    meta: { layout: 'AdminLayout', requiresAuth: true }
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: { layout: 'FullScreenLayout' }
  },
  {
    path: '/absence',
    name: 'Absence',
    component: AbsenceView,
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    meta: { requiresAuth: true } // Require authentication
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboardView,
    meta: { layout: 'AdminLayout', requiresAuth: false } // Require authentication
  },
  {
    path: '/members',
    component: AdminMemberView,
    name: 'AdminMembers',
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/members/:slug',
    name: 'AdminMembersDetail',
    component: AdminMemberDetailView,
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/users',
    component: AdminUserView,
    name: 'AdminUser',
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/users/:slug',
    name: 'AdminUserDetail',
    component: AdminUserDetailView,
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/products',
    component: AdminProductView,
    name: 'AdminProduct',
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/products/:slug',
    name: 'AdminProductDetail',
    component: AdminProductDetailView,
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/news',
    component: AdminNewsView,
    name: 'AdminNewsView',
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/news/:slug',
    name: 'AdminNewsDetail',
    component: AdminNewsDetailView,
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/transactions',
    component: AdminTransactionView,
    name: 'AdminTransaction',
    meta: { layout: 'AdminLayout', requiresAuth: true } // Require authentication
  },
  {
    path: '/content/:slug',
    component: ContentView,
    name: 'ContentView',
    meta: { requiresAuth: true } // Require authentication
  }
  // {
  //   path: '*',
  //   redirect: '/'
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// Global navigation guard to check for authentication
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check for the presence of the access token
    if (!localStorage.getItem('access_token')) {
      // If no token, redirect to login
      next('/login')
    } else {
      // If token exists, allow access
      next()
    }
  } else {
    // If route does not require authentication, proceed normally
    next()
  }
})

export default router
