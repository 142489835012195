export const MENU = [
  {
    icon: 'mdi-chart-bar',
    name: 'Dashboard',
    path: '/admin'
  },
  {
    icon: 'mdi-qrcode-scan',
    name: 'Scan',
    path: '/scan'
  },
  {
    icon: 'mdi-package-variant-closed',
    name: 'Product',
    path: '/products'
  },
  {
    icon: 'mdi-cash-multiple',
    name: 'Transaction',
    path: '/transactions'
  },
  {
    icon: 'mdi-credit-card',
    name: 'Member',
    path: '/members'
  },
  {
    icon: 'mdi-newspaper-variant-multiple',
    name: 'News',
    path: '/news'
  },
  {
    icon: 'mdi-account',
    name: 'User',
    path: '/users'
  }
]
