<template>
  <v-bottom-navigation :value="value" color="secondary" grow fixed>
    <v-btn
      link
      height="auto"
      v-for="(item, index) in items"
      :key="index"
      :to="item.path"
    >
      <span>{{ item.name }}</span>

      <v-icon>{{ item.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'GeneralBottomNavbar',
  data: () => ({
    value: 0,
    items: [
      { icon: 'mdi-home', name: 'Home', path: '/home' },
      { icon: 'mdi-qrcode-scan', name: 'Qr Code', path: '/absence' },
      { icon: 'mdi-account', name: 'Profile', path: '/profile' }
    ]
  })
}
</script>
