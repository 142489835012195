<template>
  <v-carousel cycle height="auto" hide-delimiters v-model="model">
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      @click="onRead(item.id)"
    >
      <div class="content pointer">
        <img class="v-responsive rounded-lg d-flex mx-auto" :src="item.image" />

        <div class="img-description rounded-b-lg">
          <p class="p--default two-lines">
            {{ item.title }}
          </p>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    items: Array
  },
  data: () => ({
    model: 0,
    images: ['news-1.png', 'news-2.png', 'news-3.png']
  }),

  methods: {
    onRead(id) {
      this.$emit('on:read', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
}
.img-description {
  background: rgba(102, 102, 102, 0.5);
  backdrop-filter: blur(10px);
  color: white;
  position: absolute;
  bottom: 0;
  padding: 12px;
  height: 20%;
  width: 100%;

  p {
  }
}
</style>
