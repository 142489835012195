<template>
  <div>
    <v-card class="shadow-base pa-6 rounded-lg mx-auto" max-width="600">
      <v-form v-model="state.isValid" ref="form">
        <div class="mb-6">
          <p class="h6--xsmall">Photo</p>
          <template v-if="imagePreview">
            <div class="d-flex align-start">
              <img
                width="250"
                height="250"
                class="rounded-xl cover mt-2 image-preview"
                :src="imagePreview"
                alt="img"
              />
              <v-btn icon @click="onRemoveItem()"
                ><v-icon color="error">mdi-close-circle</v-icon></v-btn
              >
            </div>
          </template>
          <!-- Product Image Upload -->
          <template v-if="!form.image">
            <v-file-input
              v-model="form.image"
              class="mt-6"
              label="Upload Image"
              prepend-icon="mdi-camera"
              accept="image/*"
              outlined
              dense
              hide-details="auto"
              @change="onFilePicked"
            />
          </template>
        </div>

        <!-- Product Name -->
        <FormTextField
          class="mb-6"
          v-model="form.title"
          label="Title"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <FormRichEditor v-model="form.content" label="Content" bold />

        <FormSelect
          v-model="form.status"
          :items="items.status"
          item-text="name"
          item-value="key"
          class="mt-6"
          label="Status"
          hide-details="auto"
          bold
          dense
          outlined
          :rules="[required]"
        />

        <!-- Action Buttons -->
        <div class="d-flex justify-end mt-8">
          <v-btn
            depressed
            class="mr-4 h7--xxsmall text-capitalize"
            :loading="state.isLoading"
            @click="onBack"
            >Back</v-btn
          >
          <v-btn
            depressed
            color="h7--xxsmall text-capitalize secondary"
            :loading="state.isLoading"
            @click="onSave()"
          >
            Save
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import alert from '@/mixins/alert'
import utils from '@/mixins/utils'
import { STATUS } from '@/data/general'

export default {
  name: 'AdminNewsDetail',
  mixins: [rules, alert, utils],
  data() {
    return {
      form: {
        title: '',
        content: '',
        status: 'active',
        image: null
      },
      imagePreview: '',
      state: {
        isLoading: false,
        isValid: true
      },
      items: {
        status: STATUS
      },
      id: null
    }
  },

  created() {
    this.id = this.$route.params.slug

    if (!this.isCreated) {
      this.getOne()
    }
  },

  computed: {
    isCreated() {
      return this.id === 'create'
    }
  },

  mounted() {
    const appBar = {
      title: 'News',
      child: true
    }

    this.$store.commit('SET_META', appBar)
  },

  methods: {
    async getOne() {
      try {
        const res = await this.$api.news.getOne(this.id)

        this.form = {
          ...res.data
        }
        this.imagePreview = this.form.image
      } catch (error) {
        this.setFailedAlert(error)
      }
    },

    onRemoveItem() {
      this.form.image = null
      this.imagePreview = ''
    },

    onFilePicked(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result // Set the data URL for the image preview
        }
        reader.readAsDataURL(file) // Read the file as a data URL
      }
    },

    // Handle saving the new product
    async onSave() {
      this.state.isLoading = true
      const valid = await this.validate(this.state.isValid)

      if (valid) {
        // Create FormData to handle file upload
        const formData = new FormData()
        formData.append('title', this.form.title)
        formData.append('content', this.form.content)
        formData.append('status', this.form.status)

        if (this.form.image) {
          formData.append('image', this.form.image)
        }

        // Send the POST request to create a new product
        const res = this.isCreated
          ? await this.$api.news.post(formData)
          : await this.$api.news.put(this.id, formData)

        if (res.success) {
          this.setSuccessAlert('Berhasil Menyimpan Data')
          this.$router.push('/news')
        }

        if (!res.success) {
          this.setFailedAlert(res)
        }
      }

      this.state.isLoading = false
    },

    onBack() {
      this.$router.push('/news')
    }
  }
}
</script>

<style>
.image-preview {
  max-width: 250px;
  max-height: 250px;
  height: auto;
  margin-top: 10px;
}
</style>
