<template>
  <div>
    <template v-if="localPaging.total">
      <div class="d-sm-flex justify-space-between align-center">
        <p class="text--default dark--text">
          Menampilkan {{ localPaging.from }} - {{ localPaging.to }} dari
          {{ localPaging.total }} data
        </p>

        <v-pagination
          class="mt-6 mt-sm-0"
          color="secondary lighten-5"
          v-model="localPaging.current_page"
          :length="localPaging.last_page"
          @input="onEmitChange"
        />
      </div>
    </template>

    <template v-if="!localPaging.total">
      <div class="h4--defaul text-center mt-14">
        <h4>Tidak ada data</h4>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'GeneralPagination',
  props: {
    paging: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      localPaging: { ...this.paging }
    }
  },

  computed: {
    pagingData() {
      return this.paging.limit * this.paging.page - (this.paging.limit - 1)
    }
  },

  methods: {
    onEmitChange(val) {
      this.$emit('on:change', val)
    }
  },
  watch: {
    paging: {
      handler(newPaging) {
        this.localPaging = { ...newPaging }
      },
      deep: true
    }
  }
}
</script>
