import router from '@/router'

const defaultState = {
  token: null,
  profile: {}
}

export const state = () => ({
  ...defaultState
})

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_PROFILE(state, payload) {
    state.profile = payload
  },
  SET_ROLE(state, payload) {
    state.role = payload
  },
  SET_PERMISSION(state, payload) {
    state.permission = payload
  }
}
export const actions = {
  /**
   * Login
   *
   * @param {String} uniqueKey
   * @param {String} password
   */
  async login({ dispatch, commit }, params) {
    let res = await this.$api.auth.login(params)

    if (res.success) {
      let { access_token, profile } = res.data

      dispatch('setToken', 'Bearer ' + access_token)
      commit('SET_PROFILE', profile)
      localStorage.setItem('profile', JSON.stringify(profile))

      // Use the imported router instance for navigation
      if (profile.role === 'admin' || profile.role === 'super_admin') {
        router.push('/admin') // Correct way to navigate
      }
      if (profile.role === 'member') {
        router.push('/home') // Correct way to navigate
      }
    }

    if (!res.success) {
      dispatch(
        'snack',
        [res.error?.message || res.message, 'error', 'mdi-close-circle'],
        {
          root: true
        }
      )
    }
  },

  /**
   * Logout
   *
   *
   */
  async logout({ commit, dispatch }) {
    let res = await this.$api.auth.logout()

    if (res.success) {
      await dispatch('unsetToken')
      commit('SET_PROFILE', {
        ...defaultState
      })
      router.push('/login') // Correct way to navigate
    }

    if (!res.success) {
      dispatch(
        'snack',
        [res.error?.message || res.message, 'error', 'mdi-close-circle'],
        {
          root: true
        }
      )
    }
  },

  /**
   * Fetch profile
   *
   *
   */
  async fetchProfile({ commit }) {
    // let payload = null
    // let res = await this.$api.auth.getProfile()

    // if (!res.success) return
    // payload = {
    //   ...res.data
    // }

    commit('SET_PROFILE', JSON.parse(localStorage.getItem('profile')))
  },

  /**
   * Set token in axios, store and localStorage
   *
   * @param {String} token
   */
  async setToken({ commit }, token) {
    localStorage.setItem('access_token', token)
    commit('SET_TOKEN', token) // store
  },

  /**
   * Remove token from axios, store and localStorage
   */
  async unsetToken() {
    localStorage.removeItem('access_token')
    localStorage.removeItem('profile')
  },

  /**
   * Set profile
   *
   *
   */
  setProfile({ commit }, payload) {
    commit('SET_PROFILE', payload)
    localStorage.setItem('profile', JSON.stringify(payload))
  }
}

export const getters = {
  isLogin(state) {
    return !!state.token
  },
  isToken(state) {
    return state.token
  },
  profile(state) {
    return state.profile
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
