var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('p',{staticClass:"text-capitalize label-text mb-2",class:{
      'h6--xsmall': _vm.bold,
      'text--default': !_vm.bold,
      'white--text': _vm.labelWhite,
      'dark--text': !_vm.labelWhite
    }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('v-select',_vm._g(_vm._b({attrs:{"search-input":_vm.search,"placeholder":_vm.placeholder},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false}},scopedSlots:_vm._u([(_vm.prepend)?{key:"prepend",fn:function(){return [_c('v-icon',{class:{
          'material-icons-outlined': _vm.prependOutline,
          'primary--text': _vm.active
        }},[_vm._v(" "+_vm._s(_vm.$attrs['prepend-icon'])+" ")])]},proxy:true}:null,(_vm.prependInner)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:{
          'material-icons-outlined': _vm.prependOutline,
          'primary--text': _vm.active
        }},[_vm._v(" "+_vm._s(_vm.$attrs['prepend-inner-icon'])+" ")])]},proxy:true}:null],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }