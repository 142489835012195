var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.label)?_c('p',{staticClass:"text-capitalize label-text",class:{
        'h6--xsmall': _vm.bold,
        'text--default': !_vm.bold
      }},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.required)?_c('p',{staticClass:"text-capitalize label-text ml-1 error--text",class:{
        'h6--xsmall': _vm.bold,
        'text--default': !_vm.bold
      }},[_vm._v(" * ")]):_vm._e()]),_c('v-autocomplete',_vm._g(_vm._b({class:['white', _vm.className],attrs:{"placeholder":_vm.placeholder},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false}},scopedSlots:_vm._u([(_vm.prepend)?{key:"prepend",fn:function(){return [_c('v-icon',{class:{
          'material-icons-outlined': _vm.prependOutline,
          'primary--text': _vm.active
        }},[_vm._v(" "+_vm._s(_vm.$attrs['prepend-icon'])+" ")])]},proxy:true}:null,(_vm.prependInner)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{class:{
          'material-icons-outlined': _vm.prependOutline,
          'primary--text': _vm.active
        }},[_vm._v(" "+_vm._s(_vm.$attrs['prepend-inner-icon'])+" ")])]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }