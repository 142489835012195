// src/plugins/axios.js
import axios from 'axios' // Import Axios
import qs from 'query-string' // Import query-string for custom params serializer

const setupAxios = ({ app, redirect }) => {
  // Create an Axios instance
  const apiUrl = process.env.VUE_APP_API_URL // Use import.meta.env.VITE_API_URL if using Vite

  // Create an Axios instance
  const instance = axios.create({
    baseURL: apiUrl // Set base URL from environment variable
  })

  // Configure request interceptor
  instance.interceptors.request.use((config) => {
    // Get the access token from localStorage
    const token = localStorage.getItem('access_token')

    // If token exists, set the Authorization header
    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  })

  // Configure response interceptor
  instance.interceptors.response.use(
    (response) => {
      return response.data // Return only the data from the response
    },
    (error) => {
      const response = error.response
      const data = { ...response?.data, status: response?.status } || {}

      if (response) {
        const errorStatuses = [403, 504]
        if (errorStatuses.includes(response.status)) {
          redirect('/logout')
        }
      }

      return data // Reject the error with the formatted response
    }
  )

  // Set common headers
  instance.defaults.headers.common['Accept'] = 'application/json; charset=utf-8'
  instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  instance.defaults.headers.common['no-cache'] = '1'

  // Set params serializer for custom queries
  instance.defaults.paramsSerializer = (params) => {
    if (params.attributes) params.attributes = JSON.stringify(params.attributes)
    if (params.filter) params.filter = JSON.stringify(params.filter)
    if (params.order) params.order = JSON.stringify(params.order)
    return qs.stringify(params)
  }

  // Make the Axios instance globally accessible as `$axios`
  app.prototype.$axios = instance
}

export default {
  install(app, options) {
    // Pass store as an option to the setupAxios function
    setupAxios({ app, ...options })
  }
}
