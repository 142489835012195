<template>
  <div>
    <MemberCard class="mb-10" :profile="profile" />

    <div class="mt-10 shadow-base">
      <div class="mb-6 mx-auto">
        <template v-if="imagePreview">
          <div class="d-flex align-start">
            <div class="d-flex mx-auto">
              <v-avatar style="border: 1px solid gainsboro" size="120" class="">
                <img
                  class="contain"
                  :src="imagePreview"
                  alt="Profile Picture"
                />
              </v-avatar>
              <v-btn v-if="state.isEdited" icon @click="onRemoveItem()"
                ><v-icon color="error">mdi-close-circle</v-icon></v-btn
              >
            </div>
          </div>
        </template>
        <!-- Product Image Upload -->
        <template v-if="!form.image && state.isEdited">
          <v-card width="300" flat class="d-flex mx-auto">
            <v-file-input
              v-model="form.image"
              class="mt-6"
              label="Upload Image"
              prepend-icon="mdi-camera"
              accept="image/*"
              outlined
              dense
              hide-details="auto"
              @change="onFilePicked"
            />
          </v-card>
        </template>
      </div>

      <template v-if="!state.isEdited">
        <v-btn
          class="d-flex mx-auto my-4 text-capitalize h7--xxsmall"
          outlined
          @click="onEdit()"
        >
          <v-icon class="" size="16">mdi-pencil</v-icon>
          Ubah Photo</v-btn
        >
      </template>

      <template v-if="state.isEdited">
        <v-btn
          width="200"
          depressed
          @click="onSave()"
          class="d-flex secondary mx-auto mt-4 text-capitalize h7--xxsmall"
          >Save</v-btn
        >
      </template>

      <div class="d-flex justify-center">
        <div class="text-center my-4">
          <h2 class="profile-name grey--text">{{ profile.name }}</h2>
        </div>
      </div>

      <div class="shadow pa-4 rounded-lg">
        <v-row align="end" v-for="(item, index) in items" :key="index">
          <v-col cols="2">
            <div class="d-flex flex-column align-start">
              <v-icon size="20" color="secondary">{{ item.icon }}</v-icon>
            </div>
          </v-col>
          <v-col>
            <p class="grey--text text--default text-capitalize">
              :
              {{ item.value }}
            </p>
          </v-col>
        </v-row>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content> </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="mt-4">
      <v-btn
        :loading="state.isLoading"
        block
        outlined
        class="mb-4 text-capitalize"
        @click="handleDialog()"
      >
        Change Password
      </v-btn>
      <v-btn
        :loading="state.isLoading"
        block
        class="error h7--xxsmall"
        @click="onLogout()"
      >
        <v-icon size="16" class="mr-2">mdi-exit-to-app</v-icon>
        Keluar
      </v-btn>
    </div>

    <v-dialog fullscreen v-model="state.dialog">
      <div class="white fill-height pa-6">
        <v-form v-model="state.valid" ref="form">
          <FormTextField
            class="mb-4"
            v-model="currentPassword"
            label="Current Password"
            bold
            outlined
            dense
            hide-details="auto"
            type="password"
            :rules="[required]"
          />

          <FormTextField
            v-model="newPassword"
            class="mb-4"
            label="New Password"
            bold
            outlined
            dense
            hide-details="auto"
            type="password"
            :rules="[required]"
          />

          <FormTextField
            v-model="confirmPassword"
            label="Confirm Password"
            bold
            outlined
            dense
            hide-details="auto"
            type="password"
            :rules="[required]"
          />
        </v-form>
        <v-row class="mt-8">
          <v-col cols="6">
            <v-btn
              :loading="state.isLoading"
              depressed
              class="text-capitalize h7--xxsmall"
              block
              @click="handleDialog()"
              >Cancel</v-btn
            >
          </v-col>
          <v-col cols="6"
            ><v-btn
              :loading="state.isLoading"
              depressed
              class="secondary text-capitalize h7--xxsmall"
              block
              @click="onChangePassowrd()"
              >Save</v-btn
            ></v-col
          >
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
export default {
  mixins: [rules],
  name: 'ProfilePage',
  data: () => ({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    form: {
      image: null
    },
    imagePreview: '',
    state: {
      isLoading: false,
      isEdited: false,
      dialog: false,
      valid: true
    },
    profile: null,
    items: [
      {
        icon: 'mdi-card-account-details-outline',
        value: '',
        label: 'Member Type'
      },
      {
        icon: 'mdi-phone',
        value: '326709837472',
        label: 'Phone'
      },
      {
        icon: 'mdi-map',
        value:
          'Jl. Masjid Al-Mujahidin RT02/05 No.36 Kec. Limo Kel. Meruyung 16515',
        label: 'Address'
      },
      {
        icon: 'mdi-gender-male-female',
        value: 'Laki-laki',
        label: 'Gender'
      }
    ]
  }),

  created() {
    this.profile = this.$store.getters['auth/profile']
    this.items[0].value = this.profile.member.member_type
    this.items[1].value = this.profile.phone
    this.items[2].value = this.profile.address
    this.items[3].value = this.profile.gender

    this.imagePreview = this.profile.image
    this.form.image = this.profile.image
  },

  methods: {
    onFilePicked(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imagePreview = e.target.result // Set the data URL for the image preview
        }
        reader.readAsDataURL(file) // Read the file as a data URL
      }
    },

    handleDialog() {
      this.state.dialog = !this.state.dialog
      this.$refs.form.reset()
    },

    onRemoveItem() {
      this.form.image = null
      this.imagePreview = ''
    },

    onEdit() {
      this.state.isEdited = !this.state.isEdited
    },

    async onChangePassowrd() {
      this.state.isLoading = true

      const valid = await this.validate(this.state.valid)

      if (valid) {
        const payload = {
          current_password: this.currentPassword,
          new_password: this.newPassword,
          new_password_confirmation: this.confirmPassword
        }

        const res = await this.$api.auth.changePassword(
          this.profile.id,
          payload
        )

        if (res.success) {
          this.setSuccessAlert('Password has been changed')
          this.state.dialog = false
        }

        if (!res.success) {
          this.setFailedAlert(res)
        }
      }

      this.state.isLoading = false
    },

    async onSave() {
      this.state.isLoading = true

      const formData = new FormData()
      formData.append('name', this.profile.name)
      formData.append('birthday', this.profile.birthday)
      formData.append('email', this.profile.email)
      formData.append('gender', this.profile.gender)
      formData.append('address', this.profile.address)
      formData.append('nik', this.profile.nik)
      formData.append('phone', this.profile.phone)
      formData.append('role', this.profile.role)
      formData.append('valid_from', this.profile.member.valid_from)
      formData.append('valid_to', this.profile.member.valid_to)
      formData.append('status', this.profile.member.status)

      if (this.isCreated) {
        formData.append('password', this.form.password)
      }

      if (this.form.image) {
        formData.append('image', this.form.image)
      }

      try {
        await this.$api.user.put(this.profile.id, formData)

        this.setSuccessAlert('Berhasil Menyimpan Data')
        this.state.isEdited = false

        const data = {
          ...this.profile
        }

        data['image'] = this.imagePreview

        this.$store.dispatch('auth/setProfile', data)
      } catch (error) {
        this.setFailedAlert(error)
      }

      this.state.isLoading = false
    },

    async onLogout() {
      this.state.isLoading = true
      await this.$store.dispatch('auth/logout')
      this.state.isLoading = false
    }
  }
}
</script>
