// src/plugins/vuetify.js
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/assets/scss/variables.scss' // Ensure this path is correct
import '@/assets/scss/app.scss' // Ensure this path is correct
import '@/assets/scss/fonts.scss' // Ensure this path is correct

Vue.use(Vuetify)

const vuetify = new Vuetify({
  customVariables: [
    '@/assets/scss/variables.scss',
    '@/assets/scss/app.scss',
    '@/assets/scss/fonts.scss'
  ],
  treeShake: true,
  theme: {
    light: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#0344DC',
          lighten4: '#3C76EA',
          lighten3: '#6398F4',
          lighten2: '#98BFFB',
          lighten1: '#CBE0FD'
        },
        secondary: {
          base: '#F48207',
          lighten4: '#F8AA43',
          lighten3: '#FBC369',
          lighten2: '#FDDC9B',
          lighten1: '#FEF0CD'
        }
        // Add other colors similarly...
      }
    }
  }
})

export default vuetify
