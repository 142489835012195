export const SORT = [
  { label: 'Newest', key: 'newest' },
  { label: 'Oldest', key: 'oldest' }
]

export const GENDER = [
  { name: 'Laki-laki', key: 'male' },
  { name: 'Perempuan', key: 'female' }
]

export const STATUS = [
  { name: 'Active', key: 'active' },
  { name: 'Inactive', key: 'inactive' }
]

export const ROLES = [
  { name: 'Super Admin', key: 'super_admin' },
  { name: 'Admin', key: 'admin' }
]

export const MEMBER_TYPES = [
  { name: 'Regular', key: 'regular' },
  { name: 'Student', key: 'student' }
]
