// src/plugins/api.js

import user from '@/api/user'
import auth from '@/api/auth'
import absence from '@/api/absence'
import product from '@/api/product'
import transaction from '@/api/transaction'
import dashboard from '@/api/dashboard'
import news from '@/api/news'

export default {
  install(app) {
    // Simulating context, like `ctx` in Nuxt
    const ctx = {
      $axios: app.prototype.$axios // Assuming $http is your Axios instance or similar
    }

    // Initialize API modules
    const api = {
      user: user(ctx),
      product: product(ctx),
      auth: auth(ctx),
      absence: absence(ctx),
      transaction: transaction(ctx),
      dashboard: dashboard(ctx),
      news: news(ctx)
      // Add more modules if needed, e.g., api.member = member(ctx)
    }

    // Injecting API into global properties for accessibility in components
    app.prototype.$api = api
  }
}
