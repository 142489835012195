// src/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    meta: {
      child: false,
      title: ''
    },
    snackbars: [
      /**
       * @param {String} id - id from setTimeout
       * @param {String} msg - message
       * @param {String} background - vuetify or html colors
       * @param {Number} timeout - timeout in ms
       * @param {String} icon - icon
       * @param {Number} show - show snackbar ui
       */
      // { id: '', msg: 'Hello', color: 'success', timeout: 5000 }
    ]
  },
  mutations: {
    SET_META(state, payload) {
      state.meta = payload
    },
    ADD_SNACK(state, payload) {
      state.snackbars = [...state.snackbars, payload]
    },
    REMOVE_SNACK(state, id) {
      let snacks = state.snackbars
      state.snackbars = snacks.filter((s) => s.id !== id)
    },
    SHOW_SNACK(state, id) {
      let snack = state.snackbars.find((s) => s.id === id)
      snack.show = true
    }
  },
  actions: {
    async meta({ commit }, message) {
      commit('SET_META', message)
    },
    async snack({ commit }, [msg, background, icon, timeout]) {
      let item = {
        msg,
        background,
        icon,
        timeout: timeout || 5000,
        show: false
      }

      item.id = setTimeout(() => {
        commit('REMOVE_SNACK', item.id)
      }, item.timeout)

      commit('ADD_SNACK', item)
      setTimeout(() => commit('SHOW_SNACK', item.id), 0)
    },
    async unsnack({ commit }, id) {
      clearTimeout(id)
      commit('REMOVE_SNACK', id)
    }
  },
  getters: {}
})
