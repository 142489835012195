<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-inline-block">
          <v-text-field
            outlined
            dense
            v-model="dateRangeText"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </div>
      </template>
      <v-date-picker v-model="dates" range />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    parentDate: Array,
    parentMenu: Boolean,
    getSevenDays: Boolean
  },

  data() {
    return {
      menu: false,
      dates: []
    }
  },

  created() {
    const today = this.$dayjs().format('YYYY-MM-DD')
    const sevenDaysAgo = this.$dayjs().subtract(7, 'days').format('YYYY-MM-DD')

    if (this.getSevenDays) {
      this.dates[0] = sevenDaysAgo
      this.dates[1] = today
      this.$emit('get:date', this.dates)
    }
  },

  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    }
  },

  watch: {
    dates: {
      handler() {
        if (this.dates.length > 1) {
          const parseStartDate = Date.parse(this.dates[0])
          const parseEndtDate = Date.parse(this.dates[1])

          if (parseStartDate > parseEndtDate) {
            this.dates = [this.dates[1], this.dates[0]]
          }
          this.$emit('get:date', this.dates)
          this.$emit('on:fetch')
          this.menu = false
        }
      },
      immediate: true
    }
  }
}
</script>
