<template>
  <v-card width="100%" class="mx-auto shadow-base">
    <v-card-title>
      <img
        @error="onErrorImage"
        :src="item.image"
        cover
        class="rounded-lg cover"
        width="100%"
        height="200"
      />
      {{ item.name }}</v-card-title
    >
    <v-card-subtitle>Price: {{ formatPrice(item.price) }}</v-card-subtitle>
    <v-card-text>Stock: {{ item.stock }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: Object
  },

  methods: {
    onErrorImage(event) {
      event.target.src = '/static/empty-img.jpg'
    },
    formatPrice(value) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR'
      }).format(value)
    },
    onSubmit() {}
  }
}
</script>

<style scoped>
.v-card {
  margin: 10px;
}
.v-img {
  object-fit: cover;
}
</style>
