<template>
  <v-app>
    <GeneralDrawer :drawer="drawer" @on:drawer="onSetDrawer" />
    <v-main class="o-app">
      <v-container>
        <router-view />
      </v-container>
      <GeneralSnackBar />
    </v-main>
  </v-app>
</template>

<script>
import GeneralDrawer from '@/components/drawer/GeneralDrawer.vue'
export default {
  name: 'App',
  components: {
    GeneralDrawer
  },
  data() {
    return {
      drawer: false
    }
  },

  methods: {
    onSetDrawer() {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style scoped>
.o-app {
  /* padding: 16px !important; */
  /* margin: 56px 0; */
}
</style>
