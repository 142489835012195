<template>
  <div class="shadow-base pa-6 rounded-xl">
    <div>
      <div class="d-flex justify-space-between align-center">
        <h2 class="primary--text text--lighten-">{{ item.project_id }}</h2>
        <v-card
          width="auto"
          :color="
            item.type === 'MATERIAL'
              ? 'success lighten-4'
              : 'secondary lighten-4'
          "
          class="pa-2 rounded d-inline-block my-4 text-center"
          flat
        >
          <p class="h7--xxsmall text-uppercase">
            {{ item.project_type }}
          </p>
        </v-card>
        <!-- <div class="d-flex flex-column mt-4 mt-md-0">
          <v-btn
            small
            depressed
            class="mb-2 success text-capitalize h7--xxsmall"
            >Complete</v-btn
          >
        </div> -->
      </div>
      <p class="text--large mb-4">{{ item.project_name }}</p>
      <p class="dark--text h7--xxsmall">Node ID : {{ item.node_id }}</p>

      <p class="dark--text text--lighten-4">
        {{ formattedDate }}
      </p>
      <v-btn
        block
        depressed
        class="primary text-capitalize h7--xxsmall mt-8"
        @click="onEmitDetail()"
        >Attendance</v-btn
      >
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    item: Object
  },
  computed: {
    formattedDate() {
      return dayjs(this.item.created_at).format('DD/MM/YYYY HH:MM')
    }
  },
  methods: {
    onEmitDetail() {
      this.$emit('on:detail')
    }
  }
}
</script>
