export default {
  computed: {
    fullDate() {
      const date = new Date()
      return date
    },
    minDate() {
      let date = new Date()
      return this.$dayjs(date).format('YYYY-MM-DD')
    }
  },

  methods: {
    toInt(val) {
      if (val) {
        return parseInt(val?.replace(/\./g, ''))
      }
    },
    decimal(val) {
      if (val) {
        return String(val)
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }

      return '0'
    },
    staticPaginationItemsFactory(items = [], page = 0, perPage = 0) {
      const start = perPage * page - perPage
      const end = start + perPage
      const newItems = items.slice(start, end)
      return newItems
    },
    setPrevAndNextNavigation(itemsLength = 0, page = 0, perPage = 0) {
      const next = itemsLength - page * perPage > 0
      const prev = page > 1
      return {
        next,
        prev
      }
    },
    onDigits(event) {
      let keyCode = event.keyCode
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },

    formatDate(val) {
      if (!val) {
        return ''
      }

      return this.$dayjs(val).format('DD-MM-YYYY')
    },

    formatDateInput(val) {
      if (!val) {
        return ''
      }

      return this.$dayjs(val).format('YYYY-MM-DD')
    }
  }
}
